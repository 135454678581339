import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/gokhanayrancioglu/IdeaProjects/gokhana.dev/node_modules/gatsby-theme-chronoblog/src/components/page/index.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const FontAwesomeIcon = makeShortcode("FontAwesomeIcon");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h4 {...{
      "id": "hello-i-am-gökhan-br--a-passionate-software-engineer"
    }}>{`Hello, I am Gökhan, `}<br />{` A passionate software engineer.`}</h4>
    <div style={{
      fontSize: '14px'
    }}> #Blogger #Kotlin #Java #Microservice-architecture </div>
    <p>{`My computer journey started with Windows 95 and continued with my encounter with software during my high school years. Since then, I have become one of those who do what they love.
I have been interested in software since my high school years, and now I mainly develop software using Microservice Architecture and languages such as Kotlin and Java.
In addition to this, I write articles on Medium to share what I have learned.`}</p>
    <p>{`After working at companies such as Netaş, Turkcell, Teknasyon, and Yemeksepeti, I continue my career at Heycar.`}</p>
    <blockquote>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": "https://superpeer.com/gokhana"
        }}>{` `}<div className="underline" style={{
            fontSize: '0.8em'
          }}>{` TI would be happy to connect with you to share my experiences, discuss technical topics, help solve problems, or support you for the future. Let's connect for a one-on-one session on Superpeer. ⟶`}</div></a>{` `}</p>
    </blockquote>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`For more articles | `}<FontAwesomeIcon icon={['fab', 'medium']} mdxType="FontAwesomeIcon" />{` `}<a parentName="p" {...{
            "href": "https://gokhana.medium.com/"
          }}>{`Medium`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`For technical and work purposes | `}<FontAwesomeIcon icon={['fab', 'linkedin']} mdxType="FontAwesomeIcon" />{` `}<a parentName="p" {...{
            "href": "https://www.linkedin.com/in/gokhanadev/"
          }}>{`Linkedin`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`For work and casual conversation | `}<FontAwesomeIcon icon={['fab', 'twitter']} mdxType="FontAwesomeIcon" />{` `}<a parentName="p" {...{
            "href": "https://twitter.com/gokhanadev"
          }}>{`Twitter`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Show me the code | `}<FontAwesomeIcon icon={['fab', 'github']} mdxType="FontAwesomeIcon" />{` `}<a parentName="p" {...{
            "href": "https://github.com/G-khan"
          }}>{`Github`}</a></p>
      </li>
    </ul>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "820px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "75.1219512195122%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQHBQb/xAAWAQEBAQAAAAAAAAAAAAAAAAADAQL/2gAMAwEAAhADEAAAAV3oFQj1rnDEP//EABoQAAMBAAMAAAAAAAAAAAAAAAIDBAUBBhT/2gAIAQEAAQUCd2RVs47fFNGm4KHwgBhTAHhTnOpT/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAIRA//aAAgBAwEBPwFtKU//xAAaEQACAgMAAAAAAAAAAAAAAAAAAQISAwQR/9oACAECAQE/AcenWLqKPD//xAAhEAACAgIBBAMAAAAAAAAAAAABAgMRABIhBRMUgSIxUf/aAAgBAQAGPwJIirrK8i7KRyORnUA7PqSO3cVXX77wP5OtqODm7M97ahVyLxppHmSyQVq794j1otfEUPrP/8QAHRABAAICAgMAAAAAAAAAAAAAAQARITFRYUFxof/aAAgBAQABPyG9xk6FilbKITK2aJFPTYlH2qnHUrDV4Iec+JTSkcpWc4AfZW+m4e2+bn//2gAMAwEAAgADAAAAEMgP/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQBx/9oACAEDAQE/EGYNq//EABoRAQACAwEAAAAAAAAAAAAAAAEAETGh0bH/2gAIAQIBAT8QNRAz5yIKs32f/8QAGxABAAIDAQEAAAAAAAAAAAAAAREhADFhQZH/2gAIAQEAAT8QBzFsYwvkyhGLaAaL6TRRdHCeQXh5IDoNRzECI+gdpd0nRfMYiCl16cAKD1d4uy4sfSLa2Jz/2Q==')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Gökhan in the Jungle!, Yedigöller",
        "title": "Yedigöller",
        "src": "/static/06230bdaef52ccc279514d19da39015d/63a81/gokhan.jpg",
        "srcSet": ["/static/06230bdaef52ccc279514d19da39015d/bd2b6/gokhan.jpg 205w", "/static/06230bdaef52ccc279514d19da39015d/ceeba/gokhan.jpg 410w", "/static/06230bdaef52ccc279514d19da39015d/63a81/gokhan.jpg 820w", "/static/06230bdaef52ccc279514d19da39015d/3470a/gokhan.jpg 1230w", "/static/06230bdaef52ccc279514d19da39015d/12a53/gokhan.jpg 1640w", "/static/06230bdaef52ccc279514d19da39015d/c2cc8/gokhan.jpg 4032w"],
        "sizes": "(max-width: 820px) 100vw, 820px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy"
      }}></img>{`
    `}</span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      